<script>
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import { mapActions, mapMutations, mapGetters } from 'vuex';
  export default {
    name: 'Home',
    components: {
      CurvedHeader,
    },
    created() {
      this.getOrganizationFromRoute();
    },
    data() {
      return {
        email: null,
        password: null,
        showPassword: false,
        passwordRules: [v => (v !== null && v.length >= 6) || 'Password should contain at least 6 characters!'],
        isLoadingOrganization: false,
        isRegisterLoading: false,
        organization: null,
        isAcceptingNewMemebersValue: false,
      };
    },
    mounted() {
      const eyesIcon = document.getElementsByClassName('v-input__append-inner')[0];
      eyesIcon?.addEventListener('click', this.toggleShowPassword);
    },
    beforeDestroy() {
      const eyesIcon = document.getElementsByClassName('v-input__append-inner')[0];
      eyesIcon?.removeEventListener('click', this.toggleShowPassword);
    },
    methods: {
      ...mapActions('OrganizationsModule', ['fetchOne', 'isAcceptingNewMemebers']),
      ...mapActions('AuthModule', ['register', 'signInToFirebaseByEmail']),
      ...mapMutations(['setErrorSnackbarMessage']),
      toggleShowPassword() {
        this.showPassword = !this.showPassword;
      },
      openTermsAndConditions() {
        window.location.href = 'https://weareluminate.co/app-terms-conditions/';
      },
      async submit() {
        const data = {
          email: this.email,
          password: this.password,
          organizationId: this.organizationId,
        };

        if (!this.$refs.form.validate()) {
          this.isRegisterLoading = false;
          return;
        }

        try {
          this.isRegisterLoading = true;
          await this.register(data);
          await this.signInToFirebaseByEmail(data);
          this.$router.push({ name: 'ChooseCategory' });
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isRegisterLoading = false;
        }
      },
      async getOrganizationFromRoute() {
        if (this.organizationId) {
          // save org id in locale storage for future use
          localStorage.setItem('register-organization-id', this.organizationId);

          // fetch organization
          try {
            this.isLoadingOrganization = true;
            this.isAcceptingNewMemebersValue = (await this.isAcceptingNewMemebers(this.organizationId)).data;
            const { data } = await this.fetchOne(this.organizationId);
            this.organization = data;
          } catch (error) {
            this.setErrorSnackbarMessage(error);
          } finally {
            this.isLoadingOrganization = false;
          }
        }
      },
      cleanEmail() {
        this.email = this.email?.trim();
      },
    },
    computed: {
      ...mapGetters('OrganizationsModule', {
        organizationLogo: 'getOrganizationLogoUrl',
      }),
      isRegisterDisabled() {
        return !this.organization || !this.isAcceptingNewMemebersValue;
      },
      organizationId() {
        return this.$route.query.organization || localStorage.getItem('register-organization-id');
      },
      eye() {
        return require('@/assets/auth/eye.png');
      },
      eyeOff() {
        return require('@/assets/auth/eye-off.png');
      },
    },
  };
</script>

<template>
  <v-progress-circular
    v-if="isLoadingOrganization"
    color="brandGrey1"
    indeterminate
    :size="49"></v-progress-circular>
  <div
    class="d-flex org-row"
    v-else>
    <!-- header -->
    <v-col
      cols="5"
      class="pa-0">
      <CurvedHeader
        title="LET’S BEGIN"
        subtitle="Create an account"
        :organization-logo="organizationLogo" />
    </v-col>

    <!-- loading -->
    <div
      v-if="isLoadingOrganization"
      class="mt-12">
      <v-progress-linear
        indeterminate
        color="primaryColor"></v-progress-linear>
    </div>

    <!-- register form -->
    <v-col
      cols="4"
      class="px-7 mx-auto"
      align-self="center"
      style="position: relative"
      v-if="!isLoadingOrganization">
      <!-- no organization detected -->
      <div
        v-if="!isLoadingOrganization && (!organizationId || !organization)"
        class="org-alert">
        <div class="org-alert-text red--text">Please use a valid invite link</div>
      </div>
      <!-- organization detected -->
      <div
        v-if="!isLoadingOrganization && organization"
        class="org-alert">
        <div class="org-alert-text">
          You’ve been invited by
          <span class="font-weight-medium">{{ organization.name }}</span>
          <div
            class="font-weight-medium red--text"
            v-if="!isAcceptingNewMemebersValue">
            Unfortunately all seats are booked
          </div>
        </div>
      </div>
      <!-- login form -->
      <div class="create-account-title mt-8 mb-6 brandGrey2--text text-center">Create an account</div>
      <v-form
        ref="form"
        @submit.prevent="submit"
        class="text-center">
        <v-text-field
          color="primaryColor"
          type="email"
          v-model="email"
          @blur="cleanEmail">
          <template slot="label"> Enter your <strong>work email address</strong> </template>
        </v-text-field>
        <v-text-field
          label="Create a password (min 8 chars)"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          hint="At least 6 characters"
          color="primaryColor"
          class="mb-2"
          v-model="password">
          <template v-slot:append>
            <v-img
              height="40"
              width="40"
              @click="showPassword = !showPassword"
              :src="showPassword ? eye : eyeOff" />
          </template>
        </v-text-field>

        <v-btn
          type="submit"
          width="80%"
          :disabled="isRegisterDisabled"
          :loading="isRegisterLoading"
          large
          color="primaryColor"
          rounded
          class="white--text"
          depressed>
          Create account
        </v-btn>
        <div class="terms-and-conditions mt-10">
          By continuing, you agree to our
          <span
            class="font-weight-medium"
            @click="openTermsAndConditions"
            >Terms and Conditions</span
          >
        </div>
      </v-form>
      <!-- Already have an account -->
      <div class="text-center brandGrey1--text already-have-account mt-4">
        Already have an account?
        <v-btn
          text
          color="primaryColor"
          class="px-0"
          @click="$router.push({ name: 'Login' })">
          Log in
        </v-btn>
      </div>
    </v-col>
  </div>
</template>

<script>
  import RegisterMixin from '@/views/auth/register/Register.mixin.vue';
  export default {
    mixins: [RegisterMixin],
  };
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  .org-row {
    height: 100%;
  }
  .org-alert {
    background: var(--v-offWhite-base);
    border-radius: 16px;
    padding: 16px;
    margin: 0 auto;
    text-align: center;
    z-index: 100;
  }
  .org-alert-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
  }

  .already-have-account {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    flex: none;
    justify-content: center;
    align-items: center;
  }
  .create-account-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  .v-text-field {
    border-bottom-color: rgba(184, 0, 0, 0.986);
  }
  .terms-and-conditions {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--v-brandGrey2-base);
    span {
      cursor: pointer;
    }
  }

  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(51, 51, 51, 0.161) !important;
  }
  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
